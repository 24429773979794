import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "react-scroll"

import IdeaValidationModal from "./IdeaValidationModal"

import DownIcon from "../../../../assets/icons/chevron-down.svg"

const MVP = ({ setContactHeadingType }) => {
  const [modalShow, setModalShow] = React.useState(false)
  return (
    <StaticQuery
      query={graphql`
        {
          MVP: file(relativePath: { eq: "mvp.jpg" }) {
            childImageSharp {
              gatsbyImageData(width: 900, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={(data) => (
        <section id="MVP" className="pt-3 pb-5">
          <div className="container">
            <div className="row position-relative">
              <div className="square-bg" />
              <div className="col-md-6">
                <h6 className="text-uppercase text-muted">
                  Minimum Viable Product (MVP)
                </h6>
                <div className="col-12 px-0 d-md-none mb-3">
                  <GatsbyImage
                    image={data.MVP.childImageSharp.gatsbyImageData}
                    alt="Minimum Viable Product (MVP)"
                    style={{
                      padding: "10px",
                      border: "10px solid #fff",
                      borderRadius: "5px",
                      boxShadow: "0px 5px 10px 0px rgba(113, 62, 90, 0.1)",
                    }}
                  />
                </div>
                <p className="h2 fw-l">
                  An MVP provides{" "}
                  <span className="text-primary">hard proof</span> that your
                  idea will be successful.
                </p>
                <p className="text-muted">
                  An MVP is a functional application used to test your target
                  market's interest in your idea. It is the 5th and final step
                  in the{" "}
                  <span
                    className="btn-inline"
                    onClick={() => setModalShow(true)}
                    onKeyDown={() => setModalShow(true)}
                    role="link"
                    tabIndex="0"
                  >
                    idea validation blueprint
                  </span>{" "}
                  and seeks a <strong>"buy in" commitment</strong> from your
                  customer. This level of commitment gives you confirmation that
                  you should start development on your application.
                </p>
                <p className="text-muted">
                  Your MVP should be solely focused on the{" "}
                  <strong>top 1-3 features</strong> that solve your user's
                  problem. It should provide an exceptional user experience
                  through minimalistic design and limited integration. This
                  approach reduces your time and financial risk. To learn more
                  about MVPs, please see the MVP section in the{" "}
                  <span
                    className="btn-inline"
                    onClick={() => setModalShow(true)}
                    onKeyDown={() => setModalShow(true)}
                    role="link"
                    tabIndex="0"
                  >
                    idea validation blueprint
                  </span>{" "}
                  or reach out to us to get started.
                </p>
              </div>
              <div className="col-md-6 d-none d-md-block mb-3">
                <GatsbyImage
                  image={data.MVP.childImageSharp.gatsbyImageData}
                  alt="Minimum Viable Product (MVP)"
                  style={{
                    padding: "10px",
                    border: "10px solid #fff",
                    borderRadius: "5px",
                    boxShadow: "0px 5px 10px 0px rgba(113, 62, 90, 0.1)",
                  }}
                />
              </div>
              <div className="col-12">
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-100}
                >
                  <button
                    className="btn btn-info"
                    onClick={() => setContactHeadingType("MVP")}
                  >
                    <span className="mr-2">Let's Build An MVP</span>
                    <DownIcon />
                  </button>
                </Link>
              </div>
            </div>
            <IdeaValidationModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </section>
      )}
    />
  )
}

export default MVP
