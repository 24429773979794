import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "react-scroll"

import IdeaValidationModal from "./IdeaValidationModal"

import DownIcon from "../../../../assets/icons/chevron-down.svg"

const LandingPage = ({ setContactHeadingType }) => {
  const [modalShow, setModalShow] = React.useState(false)

  return (
    <StaticQuery
      query={graphql`
        {
          landingPage: file(relativePath: { eq: "landing-page.png" }) {
            childImageSharp {
              gatsbyImageData(width: 900, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={(data) => (
        <section id="landingPage" className="pt-3 pb-5">
          <div className="container">
            <div className="row position-relative">
              <div className="square-bg" />
              <div className="col-md-6">
                <h6 className="text-uppercase text-muted">Landing Page</h6>
                <div className="col-12 px-0 d-md-none mb-3">
                  <GatsbyImage
                    image={data.landingPage.childImageSharp.gatsbyImageData}
                    alt="Landing page"
                    style={{
                      padding: "10px",
                      border: "10px solid #fff",
                      borderRadius: "5px",
                      boxShadow: "0px 5px 10px 0px rgba(113, 62, 90, 0.1)",
                    }}
                  />
                </div>
                <p className="h2 fw-l">
                  A landing page provides{" "}
                  <span className="text-primary">soft proof</span> that your
                  idea will be successful.
                </p>
                <p className="text-muted">
                  A landing page is used to pitch your idea to potential
                  customers. It is the 4th step in the{" "}
                  <span
                    className="btn-inline"
                    onClick={() => setModalShow(true)}
                    onKeyDown={() => setModalShow(true)}
                    role="link"
                    tabIndex="0"
                  >
                    idea validation blueprint
                  </span>{" "}
                  and is absolutely crucial in determining whether there is a
                  need for your application. Your landing page provides{" "}
                  <strong>soft proof</strong> that your idea addresses a problem
                  that truly matters to your target market.
                </p>
                <p className="text-muted">
                  To achieve this, your landing page seeks an{" "}
                  <strong>email commitment</strong> from your visitors stating
                  they will use your application upon release. Your conversion,
                  CAC, and LTV numbers are then analyzed to determine whether
                  your application will be financially viable. To learn more,
                  see the Landing Page section in the{" "}
                  <span
                    className="btn-inline"
                    onClick={() => setModalShow(true)}
                    onKeyDown={() => setModalShow(true)}
                    role="link"
                    tabIndex="0"
                  >
                    idea validation blueprint
                  </span>{" "}
                  or contact us to get started.
                </p>
              </div>
              <div className="col-md-6 d-none d-md-block mb-3">
                <GatsbyImage
                  image={data.landingPage.childImageSharp.gatsbyImageData}
                  alt="Landing page"
                  style={{
                    padding: "10px",
                    border: "10px solid #fff",
                    borderRadius: "5px",
                    boxShadow: "0px 5px 10px 0px rgba(113, 62, 90, 0.1)",
                  }}
                />
              </div>
              <div className="col-12">
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-100}
                >
                  <button
                    className="btn btn-info"
                    onClick={() => setContactHeadingType("Landing Page")}
                  >
                    <span className="mr-2">Let's Build A Landing Page</span>
                    <DownIcon />
                  </button>
                </Link>
              </div>
              <IdeaValidationModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </div>
        </section>
      )}
    />
  )
}

export default LandingPage
