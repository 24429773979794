import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "react-scroll"

import IdeaValidationModal from "./IdeaValidationModal"

import DownloadIcon from "../../../../assets/icons/download.svg"
import DownIcon from "../../../../assets/icons/chevron-down.svg"
import CheckIcon from "../../../../assets/icons/check.svg"

const IdeaValidationWhatYouGet = ({ setContactHeadingType }) => {
  const [modalShow, setModalShow] = React.useState(false)
  return (
    <StaticQuery
      query={graphql`
        {
          cardBackground1: file(
            relativePath: { eq: "idea-validation-card1.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
          cardBackground2: file(
            relativePath: { eq: "idea-validation-card2.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
          cardBackground3: file(
            relativePath: { eq: "idea-validation-card3.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={(data) => (
        <section id="ideaValidationWhatYouGet" className=" pb-5">
          <div className="container mb-3">
            <div className="row">
              <div className="col-lg-9 m-auto text-center ">
                <h2 className="text-capitalize">What You Get</h2>
                <p className="text-muted fw-l">
                  Let us help you build the foundation for your business through
                  our idea validation process. Here is what you get when you
                  work with us on your startup.
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="card text-center mb-5 shadow">
                  <div
                    className="card-header position-relative text-muted"
                    style={{ height: "6.25rem" }}
                  >
                    <GatsbyImage
                      image={
                        data.cardBackground1.childImageSharp.gatsbyImageData
                      }
                      alt=""
                      className="position-static"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "1.75rem",
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <h4 className="m-0">Idea Validation</h4>
                      <h6 className="fw-l">Learn The Fundamentals</h6>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-title h1 font-weight-bold text-muted">
                      <span className="h2 align-middle">$</span>{" "}
                      <span className="align-middle">FREE</span>
                    </div>
                    <p className="card-text mb-4">
                      Get our free idea validation blueprint to learn the
                      fundamentals. You will discover how to:
                    </p>
                    <ul className="list-group">
                      <li className="list-group-item">
                        <CheckIcon
                          className="mr-1"
                          style={{ width: "19px", height: "19px" }}
                        />
                        Critically evaluate your idea by asking 4 simple
                        questions
                      </li>
                      <li className="list-group-item">
                        <CheckIcon
                          className="mr-1"
                          style={{ width: "19px", height: "19px" }}
                        />
                        Research and understand your competitors
                      </li>
                      <li className="list-group-item">
                        <CheckIcon
                          className="mr-1"
                          style={{ width: "19px", height: "19px" }}
                        />
                        Ask your target market if they like and want your idea
                      </li>
                      <li className="list-group-item">
                        <CheckIcon
                          className="mr-1"
                          style={{ width: "19px", height: "19px" }}
                        />
                        Construct a landing page to get your first customer
                        commitment
                      </li>
                      <li className="list-group-item">
                        <CheckIcon
                          className="mr-1"
                          style={{ width: "19px", height: "19px" }}
                        />
                        Reduce your time and financial risk through proper MVP
                        practices
                      </li>
                    </ul>
                    <button
                      className="btn btn-dark btn-block mt-4"
                      onClick={() => setModalShow(true)}
                    >
                      <span className="mr-2">Get The Blueprint Free</span>
                      <DownloadIcon style={{ width: "16px", height: "16px" }} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card text-center mb-5 shadow">
                  <div
                    className="card-header position-relative text-primary"
                    style={{ height: "6.25rem" }}
                  >
                    <GatsbyImage
                      image={
                        data.cardBackground2.childImageSharp.gatsbyImageData
                      }
                      alt=""
                      className="position-static"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "1.75rem",
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <h4 className="m-0">Landing Page</h4>
                      <h6 className="fw-l">Soft Validation</h6>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-title h1 font-weight-bold text-primary">
                      <span className="h2 align-middle">$</span>{" "}
                      <span className="align-middle">259</span>
                    </div>
                    <p className="card-text mb-4">
                      We will design and develop your idea validation landing
                      page for maximum conversions. You will get:
                    </p>
                    <ul className="list-group">
                      <li className="list-group-item">
                        <CheckIcon
                          className="mr-1"
                          style={{ width: "19px", height: "19px" }}
                        />
                        Conversion focused design and development
                      </li>
                      <li className="list-group-item">
                        <CheckIcon
                          className="mr-1"
                          style={{ width: "19px", height: "19px" }}
                        />
                        Headline and benefit guidance
                      </li>
                      <li className="list-group-item">
                        <CheckIcon
                          className="mr-1"
                          style={{ width: "19px", height: "19px" }}
                        />
                        Multivariate testing (A/B testing)
                      </li>
                      <li className="list-group-item">
                        <CheckIcon
                          className="mr-1"
                          style={{ width: "19px", height: "19px" }}
                        />
                        Autoresponder integration
                      </li>
                      <li className="list-group-item">
                        <CheckIcon
                          className="mr-1"
                          style={{ width: "19px", height: "19px" }}
                        />
                        Conversion tracking information
                      </li>
                      <li className="list-group-item">
                        <CheckIcon
                          className="mr-1"
                          style={{ width: "19px", height: "19px" }}
                        />
                        A highly visible and compelling call to action button
                      </li>
                    </ul>
                    <Link
                      to="contact"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-100}
                    >
                      <button
                        className="btn btn-primary btn-block mt-4"
                        onClick={() => setContactHeadingType("Landing Page")}
                      >
                        <span className="mr-1">Let's Build A Landing Page</span>
                        <DownIcon />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card text-center mb-3 shadow">
                  <div
                    className="card-header position-relative text-info"
                    style={{ height: "6.25rem" }}
                  >
                    <GatsbyImage
                      image={
                        data.cardBackground3.childImageSharp.gatsbyImageData
                      }
                      alt=""
                      className="position-static"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "1.75rem",
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <h4 className="m-0">MVP</h4>
                      <h6 className="fw-l">Hard Validation</h6>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-title h1 font-weight-bold text-info">
                      <span className="h2 align-middle">$</span>{" "}
                      <span className="align-middle">65</span>
                      <span className="h2 align-middle">/hr</span>
                    </div>
                    <p className="card-text mb-4">
                      We will design and develop your MVP so you can get a "buy
                      in" commitment from your customers. You will get:
                    </p>
                    <ul className="list-group">
                      <li className="list-group-item">
                        <CheckIcon
                          className="mr-1"
                          style={{ width: "19px", height: "19px" }}
                        />
                        A purpose built application focused on your top 1-3
                        features
                      </li>
                      <li className="list-group-item">
                        <CheckIcon
                          className="mr-1"
                          style={{ width: "19px", height: "19px" }}
                        />
                        End-user focused design to provide your customers an
                        exceptional experience
                      </li>
                      <li className="list-group-item">
                        <CheckIcon
                          className="mr-1"
                          style={{ width: "19px", height: "19px" }}
                        />
                        Reduced time and financial risk with minimal
                        integrations
                      </li>
                      <li className="list-group-item">
                        <CheckIcon
                          className="mr-1"
                          style={{ width: "19px", height: "19px" }}
                        />
                        Professional agile project management so you stay on
                        time and budget
                      </li>
                      <li className="list-group-item">
                        <CheckIcon
                          className="mr-1"
                          style={{ width: "19px", height: "19px" }}
                        />
                        Industry leading customer service
                      </li>
                    </ul>
                    <Link
                      to="contact"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-100}
                    >
                      <button
                        className="btn btn-info btn-block mt-4"
                        onClick={() => setContactHeadingType("MVP")}
                      >
                        <span className="mr-1">Let's Build An MVP</span>
                        <DownIcon />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <IdeaValidationModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </div>
        </section>
      )}
    />
  )
}

export default IdeaValidationWhatYouGet
