import React from "react"
import axios from "axios"
import Modal from "react-bootstrap/Modal"

import PaperPlaneIcon from "../../../../assets/icons/paper-plane.svg"
import CheckIcon from "../../../../assets/icons/check.svg"

const IdeaValidationModal = (props) => {
  // State
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    interest: "",
  })
  const [formSubmitting, setFormSubmitting] = React.useState(false)
  const [formSubmitted, setFormSubmitted] = React.useState(false)
  const [errors, setErrors] = React.useState({})

  //Methods
  const formChangeHandler = (e, formField) => {
    let newFormData = { ...formData, [formField]: e.target.value }
    setFormData(newFormData)
  }

  const onSubmitButton = async (e) => {
    e.preventDefault()
    setFormSubmitting(true)
    setErrors({})

    try {
      await axios.post("https://www.tacticapps.com/api/modalForm", formData)
      setFormSubmitting(false)
      setFormSubmitted(true)
    } catch (err) {
      setErrors(err.response.data)
      setFormSubmitting(false)
    }
  }

  // Modal Content
  let modalContent

  if (formSubmitting) {
    modalContent = (
      <div className="text-center mt-5 mb-5">
        <div className="modalSpinner mt-5 mb-5">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  } else if (!formSubmitting && formSubmitted) {
    modalContent = (
      <div className="text-primary text-center mt-5 mb-5 pt-4 pb-4">
        <CheckIcon />{" "}
        <p className="h4">
          Blueprint sent.
          <br /> Please check your email.
        </p>
      </div>
    )
  } else {
    modalContent = (
      <>
        <h4 className="text-center mb-3">
          Get The Idea Validation Blueprint Delivered Straight To Your Inbox
        </h4>
        <form>
          <div className="form-row">
            <div className="form-group col-sm-6">
              <input
                type="text"
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                placeholder="First name*"
                value={formData.name}
                onChange={(e) => formChangeHandler(e, "name")}
              />
              <div className="invalid-feedback">{errors.name}</div>
            </div>
            <div className="form-group col-sm-6">
              <input
                type="email"
                className={
                  errors.email ? "form-control is-invalid" : "form-control"
                }
                placeholder="Email*"
                value={formData.email}
                onChange={(e) => formChangeHandler(e, "email")}
              />
              <div className="invalid-feedback">{errors.email}</div>
            </div>
          </div>
          <div className="form-group">
            <select
              value={formData.interest}
              onChange={(e) => formChangeHandler(e, "interest")}
              className={
                errors.interest ? "custom-select is-invalid" : "custom-select"
              }
              style={
                formData.interest === ""
                  ? { color: "#adb5bd" }
                  : { color: "#191919" }
              }
            >
              <option value="">I'm validating my idea for a...*</option>
              <option value="Web Application">Web Application</option>
              <option value="Mobile Application">Mobile Application</option>
            </select>
            <div className="invalid-feedback">{errors.interest}</div>
          </div>
          <div
            className="form-group text-center"
            style={{ marginTop: "-.7rem" }}
          >
            <small className="text-muted">
              We guarantee 100% privacy. Your information will never be shared.
            </small>
          </div>
          <button
            className="btn btn-primary btn-block mt-4"
            type="submit"
            onClick={(e) => onSubmitButton(e)}
          >
            <span className="mr-2 font-weight-bold">
              Send The Blueprint To My Inbox
            </span>
            <PaperPlaneIcon />
          </button>
          <div
            className={
              errors.server ? "invalid-feedback d-block" : "invalid-feedback"
            }
          >
            {errors.server}
          </div>
        </form>
      </>
    )
  }

  return (
    <Modal {...props} aria-labelledby="ideaValidationModal" centered>
      <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
      <Modal.Body className="pt-1 mb-3">{modalContent}</Modal.Body>
    </Modal>
  )
}

export default IdeaValidationModal
