import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const ReadyForDevelopment = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          mobileDevelopment: file(relativePath: { eq: "mobile-user.jpg" }) {
            childImageSharp {
              gatsbyImageData(width: 900, layout: CONSTRAINED)
            }
          }
          webDevelopment: file(relativePath: { eq: "web-user.jpg" }) {
            childImageSharp {
              gatsbyImageData(width: 900, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={(data) => (
        <section id="readyForDevelopment" className="pt-3 pb-5">
          <div className="container mb-3">
            <div className="row">
              <div className="col-lg-9 m-auto text-center ">
                <h2 className="text-capitalize">Ready For Development?</h2>
                <p className="text-muted fw-l">
                  With a validated idea you are ready for product development.
                  Choose your platform below to learn more about our full stack
                  development services.
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <Link to="/services/web-development/" className="col-md-6 mb-4">
                <div className="position-relative">
                  <GatsbyImage
                    image={data.webDevelopment.childImageSharp.gatsbyImageData}
                    alt="Web Development"
                    style={{
                      borderRadius: "7px",
                    }}
                  />
                  <div
                    className="light-overlay"
                    style={{
                      borderRadius: "7px",
                    }}
                  ></div>
                  <div className="development-selection-overlay">
                    <h3 className="heading text-white">Web Development</h3>
                    <p className="description text-white text-center">
                      You want your application to be accessible on all devices
                      with modern browsers.
                    </p>
                  </div>
                </div>
              </Link>
              <Link
                to="/services/mobile-development/"
                className="col-md-6 mb-4"
              >
                <div className="position-relative">
                  <GatsbyImage
                    image={
                      data.mobileDevelopment.childImageSharp.gatsbyImageData
                    }
                    alt="Mobile Development"
                    style={{
                      borderRadius: "7px",
                    }}
                  />
                  <div
                    className="light-overlay"
                    style={{
                      borderRadius: "7px",
                    }}
                  ></div>
                  <div className="development-selection-overlay">
                    <h3 className="heading text-white">Mobile Development</h3>
                    <p className="description text-white text-center">
                      You want your application to be downloadable from the
                      Apple App or Google Play Store.
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </section>
      )}
    />
  )
}

export default ReadyForDevelopment
