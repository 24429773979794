import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import IdeaValidationModal from "./IdeaValidationModal"
import DownloadIcon from "../../../../assets/icons/download.svg"

const IdeaValidation = () => {
  const [modalShow, setModalShow] = React.useState(false)

  return (
    <StaticQuery
      query={graphql`
        {
          ideaValidation: file(relativePath: { eq: "idea-validation.jpg" }) {
            childImageSharp {
              gatsbyImageData(width: 900, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={(data) => (
        <section id="ideaValidation" className="pt-3 pb-5">
          <div className="container">
            <div className="row position-relative">
              <div className="square-bg" />
              <div className="col-md-6">
                <h6 className="text-uppercase text-muted">Idea Validation</h6>
                <div className="col-12 px-0 d-md-none mb-3">
                  <GatsbyImage
                    image={data.ideaValidation.childImageSharp.gatsbyImageData}
                    alt="Idea validation blueprint"
                    style={{
                      padding: "10px",
                      border: "10px solid #fff",
                      borderRadius: "5px",
                      boxShadow: "0px 5px 10px 0px rgba(113, 62, 90, 0.1)",
                    }}
                  />
                </div>
                <p className="h2 fw-l">
                  Idea Validation is the{" "}
                  <span className="text-primary">foundation</span> for building
                  a successful startup.
                </p>
                <p className="text-muted">
                  Every startup is built on an idea but few ideas are worth
                  pursuing. For your idea to be successful, it must solve a
                  problem that truly matters to your target market. The idea
                  validation process helps{" "}
                  <span className="">
                    <strong>determine the need</strong>
                  </span>{" "}
                  for your application by asking for a{" "}
                  <span className="">
                    <strong>"buy in" commitment</strong>
                  </span>{" "}
                  from your potential customers. This foundational process{" "}
                  <span className="">
                    <strong>reduces the risk of failure</strong>
                  </span>{" "}
                  often associated with startups.
                </p>
                <p className="text-muted">
                  Ready to validate your idea? We've simplified the idea
                  validation process into 5 actionable steps and you can get
                  started today for free. Yes, we're giving away our{" "}
                  <span
                    className="btn-inline"
                    onClick={() => setModalShow(true)}
                    onKeyDown={() => setModalShow(true)}
                    role="link"
                    tabIndex="0"
                  >
                    48-page Idea Validation Blueprint for free
                  </span>
                  . Your success is our success so let us help you build your
                  business on a solid foundation.
                </p>
              </div>
              <div className="col-md-6 d-none d-md-block mb-3">
                <GatsbyImage
                  image={data.ideaValidation.childImageSharp.gatsbyImageData}
                  alt="Idea validation blueprint"
                  style={{
                    padding: "10px",
                    border: "10px solid #fff",
                    borderRadius: "5px",
                    boxShadow: "0px 5px 10px 0px rgba(113, 62, 90, 0.1)",
                  }}
                />
              </div>
              <div className="col-12">
                {
                  <button
                    className="btn btn-warning mr-3"
                    onClick={() => setModalShow(true)}
                  >
                    <span className="font-weight-bold mr-2">
                      Get The Blueprint Free
                    </span>
                    <DownloadIcon />
                  </button>
                }
                <IdeaValidationModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </div>
            </div>
          </div>
        </section>
      )}
    />
  )
}

export default IdeaValidation
