import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"

import RightIcon from "../../../../assets/icons/chevron-right.svg"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const IdeaValidationCaseStudies = () => {
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  }
  return (
    <StaticQuery
      query={graphql`
        {
          casestudy1: file(
            relativePath: { eq: "work/landing/wallet-optimizer.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 900, layout: CONSTRAINED)
            }
          }
          casestudy2: file(
            relativePath: { eq: "work/landing/coming-soon.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 900, layout: CONSTRAINED)
            }
          }
          casestudy3: file(
            relativePath: { eq: "work/landing/coming-soon.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 900, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={(data) => (
        <section id="IdeaValidationCaseStudies" className="my-3">
          <div className="container mb-3">
            <div className="row">
              <div className="col-lg-9 m-auto text-center">
                <h2 className="text-capitalize">Case Studies</h2>
                <p className=" text-muted fw-l">
                  Take a look at some of our recent landing page creations or
                  view our portfolio for more.
                </p>
              </div>
            </div>
          </div>
          <div className="container mb-5 px-5">
            <Slider {...slickSettings}>
              <Link
                to="/work/landing-pages/wallet-optimizer"
                className="position-relative"
              >
                <GatsbyImage
                  image={data.casestudy1.childImageSharp.gatsbyImageData}
                  alt="Startup case study 1"
                  style={{
                    borderRadius: "7px",
                  }}
                />
                <div
                  className="light-overlay"
                  style={{
                    borderRadius: "7px",
                  }}
                ></div>
                <div className="case-study-overlay">
                  <h3 className="heading text-white">Wallet Optimizer</h3>
                  <p className="description text-white text-center">
                    Landing page for a mobile financial management application.
                  </p>
                </div>
              </Link>

              <Link to="/work" className="position-relative">
                <GatsbyImage
                  image={data.casestudy2.childImageSharp.gatsbyImageData}
                  alt="Startup case study 2"
                  style={{
                    borderRadius: "7px",
                  }}
                />
                <div
                  className="light-overlay"
                  style={{
                    borderRadius: "7px",
                  }}
                ></div>
                <div className="case-study-overlay">
                  <h3 className="heading text-white">Coming Soon</h3>
                  <p className="description text-white text-center">
                    We're in the process of adding more landing page case
                    studies. Check back soon.
                  </p>
                </div>
              </Link>

              <Link to="/work" className="position-relative">
                <GatsbyImage
                  image={data.casestudy3.childImageSharp.gatsbyImageData}
                  alt="Startup case study 3"
                  style={{
                    borderRadius: "7px",
                  }}
                />
                <div
                  className="light-overlay"
                  style={{
                    borderRadius: "7px",
                  }}
                ></div>
                <div className="case-study-overlay">
                  <h3 className="heading text-white">Coming Soon</h3>
                  <p className="description text-white text-center">
                    We're in the process of adding more landing page case
                    studies. Check back soon.
                  </p>
                </div>
              </Link>
            </Slider>
            <div className="text-center">
              <Link to="/work/" className="btn btn-info text-uppercase my-5">
                <span>View our portfolio </span>
                <RightIcon />
              </Link>
            </div>
          </div>
        </section>
      )}
    />
  )
}

export default IdeaValidationCaseStudies
