import React, { Component } from "react"
import IdeaValidation from "./IdeaValidation"
import LandingPage from "./LandingPage"
import Mvp from "./MVP"

import LightBulbIcon from "../../../../assets/icons/light-bulb.svg"
import AircraftLanding from "../../../../assets/icons/aircraft-landing.svg"
import Devices from "../../../../assets/icons/phonelink.svg"

class StartupHelp extends Component {
  state = {
    component: "IdeaValidation",
  }

  componentChangeHandler = (component) => {
    this.setState({ component })
  }

  render() {
    const { component } = this.state
    let SelectedComponent

    if (component === "IdeaValidation") {
      SelectedComponent = (
        <IdeaValidation clickHandler={this.componentChangeHandler} />
      )
    } else if (component === "LandingPage") {
      SelectedComponent = (
        <LandingPage
          clickHandler={this.componentChangeHandler}
          setContactHeadingType={this.props.setContactHeadingType}
        />
      )
    } else if (component === "MVP") {
      SelectedComponent = (
        <Mvp
          clickHandler={this.componentChangeHandler}
          setContactHeadingType={this.props.setContactHeadingType}
        />
      )
    }

    return (
      <section id="index-contact">
        <div className="container mb-3">
          <div className="row">
            <div className="col-lg-9 m-auto text-center ">
              <h2 className="text-capitalize">How can we help?</h2>
              <p className="text-muted fw-l">
                Determine whether your idea will be successful and reduce your
                time and financial risk by using our 5-step idea validation
                blueprint.
              </p>
            </div>
          </div>
        </div>
        <div className="container mb-5">
          <div className="row text-center pb-5">
            <div className="col-12">
              <div className="radio-button-group-sm">
                <button
                  className={component === "IdeaValidation" ? "active" : null}
                  onClick={() => this.componentChangeHandler("IdeaValidation")}
                >
                  <LightBulbIcon
                    style={{
                      width: "32px",
                      height: "32px",
                    }}
                  />
                  <br />
                  Idea Validation
                </button>
                <button
                  className={component === "LandingPage" ? "active" : null}
                  onClick={() => this.componentChangeHandler("LandingPage")}
                >
                  <AircraftLanding
                    style={{
                      width: "32px",
                      height: "32px",
                    }}
                  />
                  <br />
                  Landing Page
                </button>
                <button
                  className={component === "MVP" ? "active" : null}
                  onClick={() => this.componentChangeHandler("MVP")}
                >
                  <Devices
                    style={{
                      width: "32px",
                      height: "32px",
                    }}
                  />
                  <br />
                  Minimum Viable Product
                </button>
              </div>
            </div>
          </div>
          <div className="row">{SelectedComponent}</div>
        </div>
      </section>
    )
  }
}

export default StartupHelp
