import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Element } from "react-scroll"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageHeader from "../../components/common/PageHeader"
import StartupHelp from "../../components/pages/services/startup-solutions/StartupHelp"
import ReadyForDevelopment from "../../components/pages/services/startup-solutions/ReadyForDevelopment"
import IdeaValidationWhatYouGet from "../../components/pages/services/startup-solutions/IdeaValidationWhatYouGet"
import Contact from "../../components/pages/index/Contact"
import IdeaValidationCaseStudies from "../../components/pages/services/startup-solutions/IdeaValidationCaseStudies"

const StartupSolutionsPage = () => {
  const [contactHeadingType, setContactHeadingType] = React.useState("startup")

  return (
    <StaticQuery
      query={graphql`
        {
          startup: file(relativePath: { eq: "startup-hero.jpg" }) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      `}
      render={(data) => (
        <Layout>
          <Seo
            title="Startup Solutions"
            keywords={[`startup solutions`]}
            description="Let us help you reduce your time and financial risk through idea validation. From idea to MVP to fully polished product, we partner with you every step of the way."
          />
          <PageHeader
            image={data.startup.childImageSharp.gatsbyImageData}
            title="Startup Solutions"
            subTitle="Launch your application through idea validation"
            overlay="darker"
            formLocation="onPage"
          />
          <main>
            <StartupHelp setContactHeadingType={setContactHeadingType} />
            <IdeaValidationWhatYouGet
              setContactHeadingType={setContactHeadingType}
            />
            <IdeaValidationCaseStudies />
            <ReadyForDevelopment />
            <Element name="contact">
              <Contact
                heading={
                  <>
                    Need help with your{" "}
                    <span className="text-primary">{contactHeadingType}</span>?{" "}
                  </>
                }
              />
            </Element>
          </main>
        </Layout>
      )}
    />
  )
}

export default StartupSolutionsPage
